body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.capital, .tcap {text-transform: capitalize;}
.nav-tabs>li.disabled {cursor: not-allowed;}
.nav-tabs>li {font-weight: 600}
body .portlet.light.bordered {border: none !important;}
body .portlet.light {padding: 0;}

@media (max-width: 991px)
{
  .page-sidebar.navbar-collapse.collapse{
      display: none !important;
        position:absolute;
        margin:0 !important;
        max-height:calc(100vh - 0);
        overflow-y:scroll !important;
        z-index: 1;
  }
  
}
.page-sidebar.navbar-collapse.collapse.show{
  display:block !important;
}
.margin-top-container {
  margin-top: 2px !important;
}
.min-heigh {
  min-height: 200px;
}
.login .content {
  background-color: #fff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  width: 400px;
  margin: 40px auto 10px;
  padding: 10px 30px 30px;
  overflow: hidden;
  position: relative;
}

.menu-item a {
  color: gray !important;
  padding: 5px 10px;
  font-size: 13px;
  display: block;
}

.user-icon {
  height: 35px !important;
  width: 35px !important;
  max-width: none !important;
  border-radius: 50% !important;
  margin-right: -5px !important;
  margin-top: -5px;
}

/* LODER CSS START */
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}
.loader > div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100px;
  height: 221px;
}

.lds-ripple {
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 6px solid #7aaf8a;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/* LODER CSS END */

/**ASYNC LOADER*/
.async-validating {
  position: relative;
}
.async-validating:after {
  content: "";
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 200;
  height: 16px;
  width: 16px;
  background-image: url("../innerloader.gif");
}

.min-height500 {
  min-height: 500px;
}

.preview-image {
  text-decoration: none;
  position: absolute;
  right: -11px;
  top: -33px;
  /* z-index: 1; */
  font-size: 41px;
  color: red;
  cursor: pointer;
  text-decoration: none;
}
.thumbnail {
  position: relative;
}

.avtar-preview-image {
  width: 104px;
  min-height: 141px;
  min-width: 188px;
  max-height: 141px;
}

/**Craetable**/
.hide-options .Select-arrow-zone {
  display: none;
}

.hide-options .Select-menu-outer {
  display: none;
}

.rdw-editor-main,
.rem {
  height: 250px !important;
  border: 1px solid #ededed;
  padding: 10px;
  margin-bottom: 15px;
}

.pointer {
  cursor: pointer;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10{
  margin-bottom: 10px;
}
.table .btn {
  padding: 0 10px;
}
.i-bot {
  display: block !important;
  margin-bottom: 15px;
}
.links a.active{
  font-weight: 700;
}
.links a:hover{
  font-weight: 700;
  text-decoration: none;
}
.relative {
  position: relative;
}
.c-loader {
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  z-index: 100;
}
.codearea {
  background: black !important;
  color: white !important;
}
.logo {
  width: 96px;
  margin: 0;
  padding: 3px;
}
#external {
  display: none;
}
.m-bot-30 {
  margin-bottom: 30px;
}
.m-top-30{
  margin-top: 30px;
}
.w-100{
  width: 100% !important;
}
.min-height1000 {
  min-height: 710px !important;
  height: auto;
}

.scaleup-image:hover {
  transform: scale(3);
  transition: all ease 0.5s;
  border-radius: 3px;
}
.editor-scroll {
  height: 250px !important;
  overflow: auto !important;
}
.scroller {
  height: 250px;
  overflow: auto;
}
.width-100 {
  width: 100%;
}
.wordWrap {
  word-break: break-all;
}
.portlet {
  box-shadow: none !important;
}
.box{

    border: 1px solid #e7ecf1;
    padding: 15px;

}
.portlet > .portlet-title {
  border-bottom: none !important;
}
.main {
  cursor: pointer;
}
.main > a {
  pointer-events: none;
}
.profile_img,
.profile_img:hover {
  text-decoration: none;
  background-color: #3d4957 !important;
  padding: 10px 14px 10px 10px !important;
}
.navbar-nav > li > a {
  line-height: 18px !important;
}
.page-sidebar .panel-group {
  margin-top: 43px;
}
.page-sidebar-menu a {
  color: #b4bcc8 !important;
  font-size: 15px;
}

.page-sidebar-menu {
  color: #fff;
  background-color: #313f4f;
  border-color: #ddd;
  font-weight: 100;
  font-size: 12px;
}
.page-sidebar .panel-body {
  /* border: none; */
  border: none !important;
  background: #2b3d4e;
}

.page-sidebar .panel-default > .panel-heading {
  color: #fff;
  background-color: #313f4f !important;
  border-color: #ddd;
  font-weight: 100;
  font-size: 12px;
}

.page-sidebar .panel-body {
  padding: 10px 30px 5px;
}
.page-sidebar-menu i {
  color: #b4bcc8 !important;
}
.page-sidebar-menu a:hover,
.page-sidebar-menu a:active {
  text-decoration: none;
}
.page-sidebar .panel-body p {
  margin: 7px;
}
.page-sidebar .no-child .panel-body {
  padding: 10px 15px;
}
p {
  margin: 0 0 10px;
}
.portlet {
  display: flex;
  width: 100%;
}
.portlet-body,
.portlet.light > .portlet-title {
  width: inherit;
}
li.unread {
  background-color: #e2e2e2;
}
li.unread a {
  font-weight: 600 !important;
}
.tooltip {
  z-index: 9999 !important;
}

.filter-actions {
  display: inline-block;
  margin-top: 28px;
}
.clr-both {
  clear: both;
}
.logo-text {
  color: #fff;
  margin: 12px 0 0 !important;
}
.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}
.autocomplete-dropdown-container .suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}
.cropper-drag-box {
    opacity: 0;
   background: none !important;
}
.cropper-bg {
    background-image: none !important;
}
.tdClass{
  overflow: hidden; 
    text-overflow: ellipsis; 
}

.orange-mint{
  background: rgb(242, 138, 1);
  color:white;
}

ul {
  list-style-position: inside;
}